import React from "react";
import styled from "styled-components";
import * as Widget from "./Widget";

let subtitle = `翱翔藍天與捍衛領空的空軍`;

class MissionDialog extends React.Component {
  render() {
    let { closeDialog } = this.props;
    return (
      <Wrapper>
        <Widget.Padding />
        <Widget.Row css={{ flex: 1.5, paddingLeft: 111, paddingTop: 40 }}>
          <Widget.Subtitle
            css={{ fontSize: 55, letterSpacing: 7, alignItems: "flex-start" }}
          >
            空軍任務
          </Widget.Subtitle>
        </Widget.Row>
        <Widget.Row css={{ flex: 2.5 }}>
          <Widget.Col css={{ position: "relative", flex: 2 }}>
            <img
              style={{ marginLeft: 58 }}
              src={"./images/011.png"}
              width="50px"
              height="50px"
            />
            <Widget.BtnsWrapper>
              <CloseDialogBtn onClick={closeDialog}>
                <img
                  className="back-btn"
                  src={"./images/007.png"}
                  width="115px"
                />
                <p>回前頁</p>
              </CloseDialogBtn>
              <Widget.HomeBtn to="/">
                <img
                  className="back-btn"
                  src={"./images/008.png"}
                  width="115px"
                />
                <p>回首頁</p>
              </Widget.HomeBtn>
            </Widget.BtnsWrapper>
          </Widget.Col>

          <Widget.Col css={{ flex: 3 }}>
            <Widget.Subtitle>{subtitle}</Widget.Subtitle>

            <Widget.Subtitle css={{ marginTop: 40 }}>
              <p>{`任務`}</p>
              <Widget.PaddingLine />
            </Widget.Subtitle>
            <Widget.Content css={{ marginTop: 15 }}>
              {`平時負責台海偵巡，維護台海空域安全，堅實戰備整備及部隊訓練任務，充實戰力完成戰備，適時支援各項重大災害防救工作；戰時全力爭取制空，並協同陸、海軍遂行各類型聯合作戰，以有效發揮空軍作戰之效能，殲滅進犯之敵軍，確保國土安全。`}
            </Widget.Content>
            <Widget.Subtitle css={{ marginTop: 40 }}>
              <p>{`編組`}</p>
              <Widget.PaddingLine />
            </Widget.Subtitle>
            <Widget.Content css={{ marginTop: 15 }}>
              {`空軍司令部內設各處室，另下轄空軍作戰指揮部、空軍教育訓練暨準則發展指揮部、空軍防空暨飛彈指揮部、空軍保修指揮部、空軍官校、作戰聯隊及基地指揮部等單位。`}
            </Widget.Content>
            <Widget.Subtitle css={{ marginTop: 40 }}>
              <p>{`主要武器裝備`}</p>
              <Widget.PaddingLine />
            </Widget.Subtitle>
            <Widget.Content css={{ marginTop: 15 }}>
              {`空軍主要武器裝備以戰鬥機、預警機、教練機、運輸機、救護機及各式空射飛彈與彈藥，以建構現代化空軍。`}
            </Widget.Content>
          </Widget.Col>
        </Widget.Row>

        <Widget.BottomDeco />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 84%
  );
`;

const CloseDialogBtn = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

export default MissionDialog;
