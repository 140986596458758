import React from "react";
import styled from "styled-components";
import * as Widget from "./Widget";

let subtitle = `hihi`;

class FlagDialog extends React.Component {
  render() {
    let { closeDialog } = this.props;
    return (
      <Wrapper>
        <Widget.Padding />
        <Widget.Row css={{ flex: 1.5, paddingLeft: 111, paddingTop: 40 }}>
          <Widget.Subtitle
            css={{ fontSize: 55, letterSpacing: 7, alignItems: "flex-start" }}
          >
            空軍軍旗
          </Widget.Subtitle>
        </Widget.Row>
        <Widget.Row css={{ flex: 2.5 }}>
          <Widget.Col css={{ position: "relative" }}>
            <img
              style={{ marginLeft: 58 }}
              src={"./images/011.png"}
              width="50px"
            />
            <Widget.Row css={{ marginTop: 60 }}>
              <Widget.Col css={{ marginRight: 30 }}>
                <img
                  style={{ marginLeft: 58 }}
                  src={"./images/001.png"}
                  width="320px"
                  height="220px"
                />
              </Widget.Col>
              <Widget.Col>
                <Widget.Subtitle>{`空軍旗`}</Widget.Subtitle>
                <Widget.Subtitle css={{ marginTop: 40 }}>
                  <p>{`旗式說明`}</p>
                  <Widget.PaddingLine />
                </Widget.Subtitle>
                <Widget.Content css={{ marginTop: 15 }}>
                  {`空軍旗，用天藍色為地，橫直按旗幅五分之二空軍軍徽，中綴國徽，左右輔銀色鷹翼，外繞銀色嘉禾，上綴銀色國花。`}
                </Widget.Content>
              </Widget.Col>
            </Widget.Row>

            <Widget.Row>
              <Widget.Col css={{ marginRight: 30 }}>
                <img
                  style={{ marginLeft: 58 }}
                  src={"./images/012.png"}
                  width="320px"
                  height="220px"
                />
              </Widget.Col>
              <Widget.Col>
                <Widget.Subtitle
                  css={{ marginTop: 40 }}
                >{`空軍單位旗`}</Widget.Subtitle>
                <Widget.Subtitle css={{ marginTop: 40 }}>
                  <p>{`旗式說明`}</p>
                  <Widget.PaddingLine />
                </Widget.Subtitle>
                <Widget.Content css={{ marginTop: 15 }}>
                  {`空軍單位旗，用天藍色為地，橫直按三與二之比，中置按旗幅五分之二空軍軍徽：中綴國徽，左右輔銀色鷹翼，外繞銀色嘉禾，上綴銀色國花。`}
                </Widget.Content>
                <Widget.Content css={{ marginTop: 15 }}>
                  {`邊緣加十二公分黃色絲穗，靠旗桿一邊鑲白綢一幅，中綴黑絨製成各單位番號字樣，上冠朱紅色旄，旗幅依附尺度表所定。`}
                </Widget.Content>
              </Widget.Col>
            </Widget.Row>

            <Widget.BtnsWrapper css={{ flex: 2 }}>
              <CloseDialogBtn onClick={closeDialog}>
                <img
                  className="back-btn"
                  src={"./images/007.png"}
                  width="115px"
                />
                <p>回前頁</p>
              </CloseDialogBtn>
              <Widget.HomeBtn to="/">
                <img
                  className="back-btn"
                  src={"./images/008.png"}
                  width="115px"
                />
                <p>回首頁</p>
              </Widget.HomeBtn>
            </Widget.BtnsWrapper>
          </Widget.Col>
        </Widget.Row>

        <Widget.BottomDeco />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 84%
  );
`;

const CloseDialogBtn = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

export default FlagDialog;
