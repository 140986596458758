import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { withPage } from "../PageContainer";
import FutureDialog from "../Components/FutureDialog";
import MissionDialog from "../Components/MissionDialog";
import FlagDialog from "../Components/FlagDialog";

class IntroPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFutureDialog: false,
      openMissionDialog: false,
      openFlagDialog: false
    };
  }
  render() {
    let { openFutureDialog, openMissionDialog, openFlagDialog } = this.state;
    return (
      <Wrapper>
        <img className="banner" src={"./images/004-1.png"} width="100%" />
        <Padding />
        <div style={{ display: "flex" }}>
          <BtnsWrapper>
            <BackBtn to="/about">
              <img
                className="back-btn"
                src={"./images/007.png"}
                width="115px"
              />
              <p>回前頁</p>
            </BackBtn>
            <BackBtn to="/">
              <img
                className="back-btn"
                src={"./images/008.png"}
                width="115px"
              />
              <p>回首頁</p>
            </BackBtn>
          </BtnsWrapper>
          <BtnsWrapper>
            <Btn onClick={() => this.setState({ openFutureDialog: true })}>
              <p>空軍願景</p>
            </Btn>
            <Btn onClick={() => this.setState({ openMissionDialog: true })}>
              <p>空軍任務</p>
            </Btn>
            <Btn onClick={() => this.setState({ openFlagDialog: true })}>
              <p>空軍軍旗</p>
            </Btn>
          </BtnsWrapper>
        </div>
        <BottomDeco />
        {openFutureDialog && (
          <FutureDialog
            closeDialog={() => this.setState({ openFutureDialog: false })}
          />
        )}

        {openMissionDialog && (
          <MissionDialog
            closeDialog={() => this.setState({ openMissionDialog: false })}
          />
        )}

        {openFlagDialog && (
          <FlagDialog
            closeDialog={() => this.setState({ openFlagDialog: false })}
          />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & > .banner {
    background-color: lightgray;
    border-radius: 30px 30px 0px 0px;
  }
`;

const Padding = styled.div`
  flex: 1;
`;

const BackBtn = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

const BtnsWrapper = styled.div`
  flex-grow: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > .row {
    display: flex;
    flex-direction: row;
  }
`;

const Btn = styled.div`
  text-decoration: none;
  flex: 1;
  padding: 25px 0px;
  margin: 10px;
  border: 4px solid #3e3a39;
  border-radius: 20px;

  & > p {
    font-family: "GenSekiGothic-B";
    color: #3e3a39;
    text-align: center;
    font-size: 45px;
    letter-spacing: 6pt;
  }
  :active {
    background-color: #06347a;
    & > p {
      color: #fff;
    }
  }
`;

const BottomDeco = styled.div`
  margin-top: 60px;
  height: 50px;
  border-radius: 0px 0px 30px 30px;
  background-color: #f0f0f0;
`;

export default withPage(IntroPage);
