import React from "react";
import styled from "styled-components";
import * as Widget from "./Widget";

let content = `一、建立遠距偵蒐、爭取早期預警。

二、整合C4ISR系統，有效發揮戰場管理功能。

三、自力研製、籌購遠距精準反制武器，提升反制效能。

四、規劃下一代戰機、強化整體戰力、爭取攻勢機動。

五、整合產、官、學資源，重點發展航太科技，獲取主動優勢。

六、精實兵力結構、適切組織調整，發揮編裝效能。

七、精進教育訓練，提升人員素質，達到以質勝量。`;
class FutureDialog extends React.Component {
  render() {
    let { closeDialog } = this.props;
    return (
      <Wrapper>
        <Widget.Padding />
        <Widget.Row css={{ flex: 1.5, paddingLeft: 111, paddingTop: 40 }}>
          <Widget.Subtitle
            css={{ fontSize: 55, letterSpacing: 7, alignItems: "flex-start" }}
          >
            空軍願景
          </Widget.Subtitle>
        </Widget.Row>
        <Widget.Row css={{ flex: 1 }}>
          <Widget.Col css={{ position: "relative", flex: 2 }}>
            <img
              style={{ marginLeft: 58 }}
              src={"./images/011.png"}
              width="50px"
            />
            <Widget.BtnsWrapper css={{ flex: 2 }}>
              <CloseDialogBtn onClick={closeDialog}>
                <img
                  className="back-btn"
                  src={"./images/007.png"}
                  width="115px"
                />
                <p>回前頁</p>
              </CloseDialogBtn>
              <Widget.HomeBtn to="/">
                <img
                  className="back-btn"
                  src={"./images/008.png"}
                  width="115px"
                />
                <p>回首頁</p>
              </Widget.HomeBtn>
            </Widget.BtnsWrapper>
          </Widget.Col>
          <Widget.Col css={{ flex: 3 }}>
            <Widget.Subtitle>
              以建立「早期預警、防敵奇襲、遠距接戰」之空軍戰力為目標。
            </Widget.Subtitle>
            <Widget.Content css={{ marginTop: 40 }}>{content}</Widget.Content>
          </Widget.Col>
        </Widget.Row>

        <Widget.BottomDeco />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 84%
  );
`;

const CloseDialogBtn = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

export default FutureDialog;
